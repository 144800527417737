// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-curriculo-js": () => import("./../../../src/pages/curriculo.js" /* webpackChunkName: "component---src-pages-curriculo-js" */),
  "component---src-pages-cursos-js": () => import("./../../../src/pages/cursos.js" /* webpackChunkName: "component---src-pages-cursos-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-instituto-js": () => import("./../../../src/pages/instituto.js" /* webpackChunkName: "component---src-pages-instituto-js" */),
  "component---src-pages-livros-js": () => import("./../../../src/pages/livros.js" /* webpackChunkName: "component---src-pages-livros-js" */),
  "component---src-pages-psicoterapia-js": () => import("./../../../src/pages/psicoterapia.js" /* webpackChunkName: "component---src-pages-psicoterapia-js" */)
}

